html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: inherit; /* Inherit box-sizing from the root element */
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  color: #41452d;
  text-transform: uppercase;
  font: 400 16px 'Sofia Sans', -apple-system, Roboto, Helvetica, sans-serif;
  padding: 20px; /* Ensure consistent padding */
  position: absolute;
  z-index: 99;
  width: 100%;
}

.img {
  aspect-ratio: 6.62;
  object-fit: contain;
  width: 150px;
}

.page-bar {
  display: flex;
  align-items: center;
  gap: 20px;
}

.link {
  margin: auto 0;
  white-space: nowrap;
  text-decoration: none; /* Remove default underline */
  color: #41452d; /* Link color */
  transition: color 0.3s; /* Add a transition effect */
  padding: 10px; /* Add padding for better click area */
}

.link:hover {
  color: #abaf8c; /* Change color on hover */
}

.special {
  border-radius: 60px;
  background-color: #abaf8c;
  color: #fff;
  padding: 10px 15px;
  white-space: nowrap;
  display: inline-block; /* Ensure underline follows the text */
  border-bottom: 2px solid transparent; /* Underline color */
}

.main-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 600px;
  width: 100%;
  align-items: start;
  justify-content: center;
  padding: 200px 20px;
}

.background-img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #41452d;
  margin-top: 40px;
  z-index: 10;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-block {
  font: 400 40px/0.9 Forum, sans-serif;
}

.sub-headline {
  margin-top: 12px;
  font: 300 20px/1 Sofia Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.cta {
  display: flex;
  margin-top: 40px;
  width: 100%;
  max-width: 200px;
  flex-direction: column;
  text-transform: uppercase;
  font: 400 18px/1 Sofia Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.horizontal-divider {
  background-color: #41452d;
  min-height: 2px;
  margin-top: 4px;
  width: 100%;
}

.image-column {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 20px;
}

.secondary-img {
  aspect-ratio: 0.87;
  object-fit: contain;
  width: 100%;
}

@media (max-width: 991px) {
  .nav-bar {
    padding: 0 20px;
  }

  .content-container {
    padding: 100px 20px;
  }

  .text-block {
    font-size: 30px;
  }

  .sub-headline {
    font-size: 16px;
  }

  .cta {
    margin-top: 30px;
  }

  .image-column {
    width: 100%;
    order: 1; /* Image will appear below text on smaller screens */
  }

  .text-container {
    order: 2; /* Text will appear above the image */
  }
}

@media (max-width: 768px) { /* iPad and smaller devices */
  .content-container {
    padding: 80px 20px;
  }

  .text-block {
    font-size: 24px;
  }

  .sub-headline {
    font-size: 14px;
  }

  .cta {
    margin-top: 20px;
  }

  .main-container {
    flex-direction: column;
  }

  .nav-bar {
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
  }
}

